import * as $ from 'jquery/dist/jquery.js';
import Inputmask from 'inputmask/dist/inputmask/jquery.inputmask.js';
import Swal from 'sweetalert2'
import 'bootstrap-social/bootstrap-social.css'

$(document).ready(function(){

  function sign_in_swal_init(){
    $(document).find('.ic-input').inputmask("999999-99-9999");
  }

  function sign_links_swal(){
    Swal.fire({
      title: 'Sign In',
      html:sign_links,
      confirmButtonText:'Close',
      showCloseButton: true
    });
  }

  function ic_sign_in_swal(){
    Swal.fire({
      title: 'Sign In',
      html:sign_in_form,
      confirmButtonText:'Sign In',
      cancelButtonText: 'Back',
      showCloseButton: true,
      showCancelButton: true,
      onRender: sign_in_swal_init
    }).then((result) => {
      if (result.value) {
        $(document).find('.login-btn')[0].click();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sign_links_swal(); 
      }
    });
  }

  $(document).on('click','.sign-in-link',function(e){
    e.preventDefault();
    var target = e.currentTarget;
    sign_links_swal();
  })

  $(document).on('click','.ic-sign-in-link',function(e){
    e.preventDefault();
    var target = e.currentTarget;
    ic_sign_in_swal();
  })


})
